.heroArea {
  display: flex;
}

.leftAreaRoot {
  flex: 1;
  /* border: 1px solid gray; */
  height: 100px;
  text-align: center;
}

.leftAreaSubContainer {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
}

.leftAreaSubHeader {
  font-size: 35px;
  font-weight: bold;
}

.leftAreaSubDescription {
  font-size: 15px;
  opacity: 0.7;
  padding: 20px;
}

.rightAreaRoot {
  flex: 1;
  /* border: 1px solid gray;
  text-align: center; */
}

.rightAreaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  width: 400px;
  height: 450px;
}

.rightAreaImage {
  width: 100%;
  height: 100%;
}
